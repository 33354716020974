import React from 'react';
import Layout from '../components/layout';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { graphql } from "gatsby";

const Product = ({ data }) => {

    const product = data.markdownRemark.frontmatter;

    const itemImageList = product.listaProductos.map(item => {
        return(
            <img alt={item.descripcion} src={item.imagen} />
        )
    });

    const itemDetailsList = product.listaProductos.map(item => {
        return(
            <p className='text-center'><span className='font-extrabold'>
                {item.title}</span>: {item.descripcion}
            </p>
        )
    });

    var sliderSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    return (
        <Layout textHeader='text-gitman-black'>
            <div id='product-container' className='flex flex-col w-screen pb-16'>
                <div id='product-hero' className='pt-24 flex flex-col justify-center w-screen items-center'>
                    <div className='pt-10 flex flex-col justify-center md:mt-20'>
                        <div className='mx-10'>
                            <h1 className='text-4xl font-extrabold text-center'>{product.title} ${product.precio}</h1>
                        </div>
                    </div>
                    <div className='pt-10 md:max-w-1/2'>
                        <img alt={product.descripcion} src={product.galeria[0]} />
                    </div>
                </div>
                <div id='details' className='py-10 flex flex-col justify-center gap-5'>
                    <h2 className='text-xl font-extrabold text-center'>Detalles</h2>
                    <p className='mx-10 text-lg text-center tracking-normal leading-5'>{product.descripcion}</p>
                </div>
                <div className='md:flex md:flex-row md:justify-evenly'>
                    <div className='flex flex-col'>
                        <p className='pt-10 text-lg font-extrabold text-center'>Productos</p>
                        <div className='mx-10'>
                            {itemDetailsList}
                        </div>
                    </div>
                    <div id='slider' className='py-10 w-full md:p-0 md:w-1/2'>
                        <Slider {...sliderSettings}>
                            {itemImageList}
                        </Slider>
                    </div>
                </div>
                <div className='p-10 text-gitman-green'>
                <p>* Los productos pueden variar de acuerdo a disponibilidad, se sustituiran con productos de similar calidad</p>
                </div>
            </div>
        </Layout>
    );
};

// query is filtering by slug passed by gatsby-node.js in context
export const query = graphql`
query($slug: String!){
    markdownRemark(frontmatter:{slug: {eq: $slug }}){
        frontmatter {
            title
            precio
            galeria
            descripcion
            listaProductos {
                descripcion
                imagen
                title
            }
        }
    }
}
`

export default Product;